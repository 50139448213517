// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://feasyapi.qa.pulte.com',
    azureClientId: '1e8a8505-d1a1-498f-8a9d-082c63c7c089',
    sharepointSiteUrl: 'https://pulte.sharepoint.com/sites/LandFeasibilityBookQa',
    documentationSiteUrl: 'https://pulte.sharepoint.com/sites/LandFeasibilityBookqa/How%20To/Forms/AllItems.aspx',
    anaplanUrl: 'https://anaplan.pulte.com',
    name: 'QA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
